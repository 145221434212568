import React from "react";
import classNames from "classnames";

// COMPONENT
import { TermsAndConditionsLayout } from "../../layouts/TermsAndConditions";
import { Link, Space, FontBold } from "../../components/PrivacyPageComponents";

// UTILS
import { OI_DOMAIN_URL, LINK_TYPES } from "../../utils/commonUtils";

const { MAIL, PHONE } = LINK_TYPES;

const NormalBulletText = ({ number, title, description, className, key }) => (
  <div className={classNames("flex flex-row", className)} key={key}>
    <div>
      <FontBold text={`${number})`} />
    </div>
    <div className="ml-2">
      <div>
        <FontBold text={title} />
      </div>
      <div>{description}</div>
    </div>
  </div>
);

const workFlowOptions = [
  {
    serial: "a",
    value:
      "Facilitate introduction call between Influencer and Brand, if required. The parties discuss the Campaign expectations.",
  },
  {
    serial: "b",
    value:
      "After the initial introduction call, briefs are made on the Platform and assigned to each Influencer.",
  },
  {
    serial: "c",
    value:
      "Expected delivery timelines are added per conversations with the Brand team and Influencer availability",
  },
  {
    serial: "d",
    value:
      "Once the briefs are sent, Influencers share their scripts with the Company within 1-2 business days. The same is then shared for approval of the Brand.",
  },
  {
    serial: "e",
    value:
      "Once the Brand reviews the script, they put in their suggestions/approval on the script",
  },
  {
    serial: "f",
    value:
      "The Influencer then shoots the approved content within 3-4 business working days and shares the Campaign Deliverable with the Company for approval",
  },
  {
    serial: "g",
    value:
      "The Company does the first level of quality check, to cross check the Campaign Deliverable with the approved Campaign Materials and specifications of the Brand, and suggests changes, if any. The Company then shares the Campaign Deliverable ahead to the Brand to incorporate their feedback as well",
  },
  {
    serial: "h",
    value:
      "Once the feedback is received from the Brand, the same is shared with the Influencer and revised Campaign Deliverable is expected",
  },
  {
    serial: "i",
    value:
      "Once the revised Campaign Deliverable is received, it is shared for approval along with other necessities like captions, coupon codes, etc. Post approval from the Brand, the Influencer is instructed to post the Campaign Deliverable.",
  },
  {
    serial: "j",
    value:
      "Post execution is completed, Influencers are asked to fill the payment form to release their payment in due time.",
  },
];

const additionalTermsForBrandsAndInfluencer = [
  {
    serial: "a",
    value:
      "The Brand does not have the right to select the Influencer in the case of the One pop Program. The Company will select the Influencer for the Brand’s Campaign.",
  },
  {
    serial: "b",
    value:
      "Once the Campaign Deliverable is submitted to the Brand for review, the Campaign Deliverable must be reviewed, approved and accepted by the Brand within 24 hours from the date of submission, in the event that the Brand fails to review, approve and accept the Campaign Deliverable within the aforementioned timeline, the Campaign Deliverable will be auto-accepted. Except if anything is agreed to the contrary by the Company, the Brand and the Influencer, In the event that the Brand has any revision requests on the Campaign Deliverable such revisions are acceptable to the tune of 1 (one) iteration only. After the revisions are made pursuant to comments from the Brand, further iterations and comments are not within the scope of this One pop Program. Under the One pop Program, no refund will be processed on the grounds that the final Campaign Deliverable is not to the expectations or liking of the Brand.",
  },
  {
    serial: "c",
    value:
      "The Brand hereby agrees and understands that the picture appearing on the selection screen at the time of creating Campaign is only for the purpose of reference. It is not a guarantee of allocation of specific Influencer for the Campaign. Influencer shall be allocated as per availability.",
  },
  {
    serial: "d",
    value: (
      <div>
        Pricing and Plans: The One pop Program will be made available to the
        Brand on the pricing plans set out where the One pop Program is hosted.
        The Plans can be availed as a subscription model (“Subscription”). This
        means that when you choose to avail our One pop Program, you will be
        billed as per the Plan chosen (
        <FontBold text="“Plan Validity”" />
        ). We reserve the right to alter/modify/suspend/discontinue any terms
        and conditions associated with the Plan including but not limited to the
        period, rate, services included in the Plan. Further, we shall give you
        notice of any change made to the features of the Plan prior to the
        expiry of your applicable Billing Cycle. We may change the Subscription
        Fees charged to you for the Services at any time, provided that, for
        Services billed on a subscription basis, the change will become
        effective only at the end of the then-current Billing Cycle of your
        Subscription. We will provide you with advance notice of any change in
        fees.
      </div>
    ),
  },
  {
    serial: "e",
    value:
      "The Campaign Deliverables created under the One pop Model will become the property of the Company and the Influencer merely grants to the Brand a limited license to use and publish the Campaign Deliverables on platforms for their marketing and advertising purposes. The validity of the license will be co-terminus with the Plan Validity opted for by the Brand. In the event that the Brand wishes to purchase the Campaign Deliverable or extend the term of the license, the same may be discussed at an additional cost.",
  },
  {
    serial: "f",
    value:
      "The Influencer and the Brand hereby agree and accept that the Deliverable Owner in case of Campaign Deliverables generated under the One pop Program will be the Company.",
  },
  {
    serial: "g",
    value:
      "Any Influencer operating under the One pop Program cannot reject more than 20% of the Campaigns that are provided to them in any given month. By way of illustration, if an Influencer is given 5 Campaigns in a calendar month, they may reject only 1 Campaign. Once all rejections are utilised in a month, the Influencer must work for the subsequently offered Campaigns.",
  },
];

const BulletArray = [
  {
    title: "CLICK TO ACCEPT",
    description: `Before using certain areas of the Platform you may be asked
to indicate your acceptance of the terms and conditions by
clicking a button marked "I Accept" or "I Agree" or "Okay" or "I
Consent" or other words or actions that similarly
acknowledge your consent or acceptance of a Click-Through
Terms and Conditions or you may indicate your acceptance by
executing an order form issued by the Company. Any consent
so provided by you will be deemed to be a valid consent under
all applicable laws.`,
  },
  {
    title: "UPDATION OF TERMS AND CONDITIONS",
    description: `Your use of the Platform is subject to the Terms and Conditions, which may be updated, amended, modified, or revised by us from time to time. To ensure that you are aware of any additions, revisions, amendments, or modifications that we may have made to these Terms and Conditions, it is important for you to refer to the Terms and Conditions from time to time. The updated Terms and Conditions shall be effective immediately and shall supersede these. We shall not be under an obligation to notify you of any changes to the Terms and Conditions. You shall be solely responsible for reviewing the Terms and Conditions from time to time for any modifications. If you continue to use the Platform and/or Services after the updated Terms and Conditions have been published, it shall be deemed that you have read and understood and accept the updated Terms and Conditions. Any reference to the Terms herein shall refer to the latest version of the Terms.`,
    className: "mt-5",
  },
  {
    title: "WEBSITE SERVICES",
    description: (
      <ol className="list-disc">
        <li>
          For Brands
          <div className="mt-2">
            The Services offered to the Brands are as follows:
            <ol className="list-decimal pl-5">
              <li>Campaign Creation</li>
              <li>
                Based on the campaign requirements discovering Influencers
                through various filters
              </li>
              <li>Shortlisting Influencers</li>
              <li>
                Adding deliverables against shortlisted creators including
                content rights and exclusivity
              </li>
              <li>
                Based on the deliverables added requesting price for the
                requirements
              </li>
              <li>
                Once the price is shared, option to negotiate the price or
                confirm the creator
              </li>
              <li>Content briefing and moderation</li>
              <li>
                Tracking performance of each creator/influencer and its
                deliverable in a campaign
              </li>
            </ol>
          </div>
          <div className="mt-2">
            As a Brand, you may be required to comply with some additional terms
            and conditions as set out
            <Space />
            <Link text="here" href="/brand-terms-and-conditions" />
          </div>
        </li>
        <li className="mt-2">
          For Influencers
          <div className="mt-2">
            The Services offered to Influencers are as below through platform or
            manually:
            <ol className="list-decimal pl-5">
              <li>Sharing brand leads with specific deliverables</li>
              <li>
                Taking prices for the brand requirements shared and uploading it
                on the platform and sharing it with the brands
              </li>
              <li>Renegotiating prices on behalf of the brand requests</li>
              <li>
                Sending confirmations once brand has confirmed the creator
              </li>
              <li>
                Liaising between the brand and creator to facilitate the
                preparation of content and its approval
              </li>
              <li>Exclusive talent management services</li>
            </ol>
          </div>
          <div className="mt-2">
            As an Influencer, you may be required to comply with some additional
            terms and conditions as set out
            <Space />
            <Link text="here" href="/influencer-terms-and-conditions" />
          </div>
          <div className="mt-2">
            Please note, the above list is not exhaustive, the Company may offer
            additional or ancillary services to the Brands and Influencers. Any
            such services that are offered will be governed under these Terms
            unless otherwise agreed by the parties.
          </div>
          <div className="mt-10">
            It is hereby clarified that the term "you" or "your" or “User” could
            mean the Brand or the Influencer or both, as the case may be.
          </div>
        </li>
        <li className="mt-2">
          Platform Offering
          <div className="mt-2">
            The Company offers a Platform that connects the Brands with
            Influencers to promote brand marketing campaigns and facilitate
            payments. Once an Account is created on the Platform, they can
            initiate the creation of a campaign (“Campaign”) for a
            product/service of the Brand which will be the subject matter of the
            Campaign (“Campaign Subject Matter”). The Brand shall be solely
            responsible for the contents of the Campaign
          </div>
          <div className="mt-2">
            The Campaign will be listed on the Platform. Except as may be agreed
            by the parties, the Brand is solely responsible for the management
            and administration of the Campaign. By making any content regarding
            a Campaign available through the Platform or Services, the Brand
            hereby grants to the Company a perpetual, irrevocable,
            non-exclusive, non-transferable, non-sublicenseable, worldwide,
            royalty-free license to use, copy, reproduce, display, modify,
            adapt, transmit and distribute copies of the details of the
            Campaign, for the sole purpose of providing the Services and for
            identifying the Brand and the Campaign as a customer of the Company
            on the Company’s website, pitches and other social media platforms
            The Company reserves the right to reject a Campaign for any reason
            in its sole discretion.
          </div>
          <div className="mt-2">
            The Brand may then browse through the available Influencers and
            select the Influencers that they wish to collaborate with and send /
            receive Quotes from, while strictly adhering to and following the
            rules and scope decided by the Brand for the Campaign.
          </div>
          <div className="mt-2">
            Once the Brand identifies a prospective Influencer, it will be
            reported to the Company and then the Company will commence the
            process of facilitating commercial negotiations between the Brand
            and the Influencer.
          </div>
          <div className="mt-2">
            “Brand” means a User who is registered on the website for availing
            the Campaign Management Services.
          </div>
          <div className="mt-2">
            “Influencer” means a User who is registered on the Platform or
            otherwise included in the Company’s network of social media
            influencers and celebrities, for rendering services to Brands for
            marketing Campaigns.
          </div>
          <div className="mt-2">
            Influencer Replacement: The Brand understands that the Company
            cannot guarantee the participation of the Influencer for a Campaign
            even after confirmation, there are several dynamic factors that
            could render the Influencer unable to complete the commitment, in
            such cases, the Company will strive to replace the Influencer with a
            new Influencer within 7 days of the previously confirmed Influencer
            being unavailable for the Campaign. The replacement Influencer will
            have same or higher number of followers/reach as the Influencer
            previously confirmed by the Brand.
          </div>
          <div className="mt-2">
            Work Flow Process: The Company will act as a facilitator between the
            Influencer and the Brand throughout the Campaign. An illustration of
            the Company’s facilitation in the completion a Campaign and creation
            of the Campaign Deliverables has been set out below:
          </div>
          <div className="mt-4">
            {workFlowOptions.map(({ serial, value }, index) => (
              <div
                className="flex flex-row"
                key={`${index}_${serial}_workFlow`}
              >
                <div className="mr-4">{serial}.</div>
                <div>{value}</div>
              </div>
            ))}
          </div>
          <div className="mt-6">
            Both Influencers and Brands need to ensure that no campaign or post
            is false or misleading and must disclose the fact that the
            Influencer is being paid by the Brand or the information about the
            material connection between the Brand and the Influencer.
          </div>
          <div className="mt-2">
            A “Material Connection” is any connection between an advertiser and
            influencer that may affect the weight or credibility of the
            representation made by the Influencer. Material Connection could
            include but is not limited to benefits and incentives, such as
            monetary or other compensation, free products with or without any
            conditions attached including those received unsolicited, discounts,
            gifts, contest and sweepstakes entries, trips or hotel stays, media
            barters, coverage, awards or any family or employment relationship,
            etc.
          </div>
        </li>
      </ol>
    ),
    className: "mt-5",
  },
  {
    title: "ELIGIBILITY AND ACCOUNT REGISTRATION",
    description: (
      <div className="flex flex-col">
        <div className="flex flex-row">
          <div className="mr-4">a)</div>
          <div>
            By registering on the Platform, you affirm that you are at least 18
            years of age (except as stated below) and are fully able and
            competent to accept the Terms and Conditions and the obligations,
            affirmations, representations, warranties set forth in the Terms and
            Conditions, and to abide by and comply with the terms stated
            therein. Registration of User in the Platform is available only to
            persons who can form legally binding contracts. Persons who are
            "incompetent to contract" within the meaning of the applicable law
            including minors, un-discharged insolvents, or person with unsound
            mind etc. are not eligible to use the Platform. Provided, if you are
            an Influencer, the following criteria will be also be applicable to
            creating an Account: (a) be at least 16 years old; (b) have an
            active and engaged social media profile; and; (c) have a social
            media profile that is aligned with Brand values.
          </div>
        </div>
        <div className="flex flex-row">
          <div className="mr-4">b)</div>
          <div>
            An account on the Platform in the Brand’s name or an offline profile
            of the Influencer on the Company’s network of social media
            influencers and celebrities (an <FontBold text="“Account”" />) is
            required for the purpose of rendering services under these Terms.
            When your Account is being created, you must: (i) provide accurate
            and complete information; (ii) promptly update your Account
            information with any new information that may affect the operation
            of your Account; (iii) authorize Company to make any inquiries we
            consider necessary or appropriate to verify your Account information
            or the information you provide to us via the Platform including
            document proofs or copies or any such information as required
            mandatorily by applicable law that need to be provided to us; and
            (iv) acknowledge and accept any applicable policies, including but
            not limited to those pertaining to service quality, confidentiality,
            User integrity, anti-harassment and conflict of interest. You will
            not use false identities or impersonate any other person or use
            another account that you are not authorized to use on any computer,
            mobile phone, tablet, or other device (collectively
            <Space />
            <FontBold text="“Device”" />
            ).
          </div>
        </div>
        <div className="flex flex-row">
          <div className="mr-4">c)</div>
          <div>
            You are responsible for safeguarding and maintaining the
            confidentiality of your Account information. You agree not to
            disclose your Account information to any third party and that you
            are entirely and solely responsible for any and all activities or
            actions that occur pursuant to the use of your Account on the
            Platform, whether or not you have authorized such activities or
            actions. You will immediately notify the Company of any unauthorized
            use of your Account. You may be held liable for losses incurred by
            Company due to authorized or unauthorized use of your Account as a
            result of your failure in keeping your Account Information secure
            and confidential.
          </div>
        </div>
        <div className="flex flex-row">
          <div className="mr-4">d)</div>
          <div>
            If you provide any information that is untrue, inaccurate, not
            current or incomplete (or becomes untrue, inaccurate, not current or
            incomplete), or Company has reasonable grounds to suspect that such
            information is untrue, inaccurate, not current or incomplete,
            Company has the right to suspend or terminate your Account and
            refuse any and all current or future use of the Platform / Services
            (or any portion thereof).
          </div>
        </div>
      </div>
    ),
    className: "mt-5",
  },
  {
    title: "PLATFORM LICENSE",
    description: (
      <div className="flex flex-col">
        <div className="flex flex-row">
          <div className="mr-4">a)</div>
          <div>
            Subject to your compliance with these Terms, Company grants you a
            limited, non-exclusive, non-transferable license: (i) to view any
            content of Platform; and (ii) to access, modify, edit and download
            any content, to which you are permitted access solely for your use.
            You have no right to sublicense the license rights granted herein.
          </div>
        </div>
        <div className="flex flex-row">
          <div className="mr-4">b)</div>
          <div>
            You will not use, copy, adapt, modify, prepare derivative works
            based upon, distribute, license, sell, transfer, publicly display,
            publicly perform, transmit, stream, broadcast or otherwise exploit
            the Platform and Services, except as expressly permitted in the
            Terms. No licenses or rights are granted to you by implication or
            otherwise under any Intellectual Property Rights owned or controlled
            by Company or its licensors, except for the licenses and rights
            expressly granted in the Terms. The Platform and the Intellectual
            Property Rights vested therein is owned by Company.
          </div>
        </div>
        <div className="flex flex-row">
          <div className="mr-4">c)</div>
          <div>
            You agree that this Platform and its Services are made available to
            you on a non-exclusive, non-transferable, non-sublicensable and on a
            limited license basis and hence, you will not permit, enable,
            introduce or facilitate other persons to participate in availing
            Services, including others who may be subject to an agreement that
            is the same or similar to these Terms and Conditions.
          </div>
        </div>
      </div>
    ),
    className: "mt-5",
  },
  {
    title: "FEES AND PRICING",
    description: (
      <div className="flex flex-col">
        <div>
          The fees and pricing for each campaign of a Brand and the fees payable
          to an Influencer vary on a Campaign-to Campaign basis and will be
          discussed based on estimates shared via emails or other means from the
          Brand/Influencer as the case may be. Based on the costing for a
          Campaign, the costing for an Influencer will be shared with the Brand
          and the same will be confirmed by the Brand prior to the Influencer
          rendering services for the respective Campaign, further details on
          this will be as available on the links set out below.
        </div>
        <div className="mt-2">
          <FontBold text="If you are a Brand:" /> The payment terms are set out
          in the Brand Terms and Conditions available
          <Space />
          <Link text="here" href="/brand-terms-and-conditions" />.
        </div>
        <div className="mt-2">
          <FontBold text="If you are an Influencer:" /> The payout details are
          set out in the Influencer Terms and Conditions available
          <Space />
          <Link text="here" href="/influencer-terms-and-conditions" />.
        </div>
      </div>
    ),
    className: "mt-5",
  },
  {
    title: "REFUND AND CANCELLATION",
    description: (
      <>
        <div className="mt-2">Cancellation by Brand:</div>
        <div className="mt-2">
          A Brand may cancel the services of an Influencer in the manner as set
          out in the Brand Terms and Conditions.
        </div>
        <div className="mt-2">Cancellation by Influencer:</div>
        <div className="mt-2">
          An Influencer may cancel their engagement in a Campaign in the manner
          as set out in the Influencer Terms and Conditions.
        </div>
      </>
    ),
    className: "mt-5",
  },
  {
    title: "RULES AND CODE OF CONDUCT",
    description: (
      <div className="flex flex-col">
        <div className="flex flex-row">
          <div className="mr-4">a)</div>
          <div>
            You shall not use the Platform for any purpose that is prohibited by
            the Terms; or other rules or policies implemented by us from time to
            time.
          </div>
        </div>
        <div className="flex flex-row">
          <div className="mr-4">b)</div>
          <div>
            You shall comply with all applicable local, provincial laws, and
            regulations in connection with your availing of the Services.
          </div>
        </div>
        <div className="flex flex-row">
          <div className="mr-4">c)</div>
          <div>
            You shall keep Company informed of any technical issues or problems
            with the Platform, as and when the issues develop.
          </div>
        </div>
        <div className="flex flex-row">
          <div className="mr-4">d)</div>
          <div>
            By way of example, and not as a limitation, you shall not (and shall
            not permit any third party to) take any action (including without
            limitation host, display, upload, modify, publish, transmit, store,
            update or sharing of information) that: (i) would constitute a
            violation of any applicable law, rule or regulation or belongs to
            another person; (ii) infringes on any intellectual property or other
            right of any other person or entity; (iii) is threatening, abusive,
            harassing, defamatory, libelous, deceptive, fraudulent; or (iv)
            impersonates any person or entity (v) deceives or misleads the
            addressee about the origin of the message or knowingly and
            intentionally communicates any information which is patently false
            or misleading in nature but may reasonably be perceived as a fact,
            (vi) contains software virus or any other computer code, file or
            program designed to interrupt, destroy or limit the functionality of
            any computer resource or (vii) is patently false and untrue, and is
            written or published in any form, with the intent to mislead or
            harass a person, entity or agency for financial gain or to cause any
            injury to any person. The Company reserves the right to disable any
            Account at any time for any reason (including, but not limited to,
            upon receipt of claims or allegations from third parties or
            authorities; or if Company is concerned that you may have violated
            the Terms of Use), or for no reason at all with or without notice to
            the User.
          </div>
        </div>
        <div className="flex flex-row">
          <div className="mr-4">e)</div>
          <div>
            Additionally, you shall not share any information that: (i) may be
            harmful to minors or children below the age of 18 (eighteen) years;
            (ii) threatens the unity, integrity, defence, security or
            sovereignty of the country, friendly relations with foreign states
            or public order or causes incitement to the commission of any
            cognisable offence or prevents investigation of any offence or is
            insulting any other nation; and (iii) is invasive of another’s
            privacy, hateful, or racially, ethnically objectionable,
            disparaging, relating or encouraging money laundering or gambling,
            or otherwise unlawful in any manner whatever; (iv) infringes any
            patent, trademark, copyright or other proprietary rights; (v)
            violates any laws for time being in force.
          </div>
        </div>
        <div className="flex flex-row">
          <div className="mr-4">f)</div>
          <div>
            Furthermore, you shall not (directly or indirectly): (i) take any
            action that imposes or may impose an unreasonable or
            disproportionately large load on Company’s (or its third party
            providers’) infrastructure; (ii) interfere or attempt to interfere
            with the proper working of the Platform or any activities conducted
            on the Platform; (iii) bypass any measures we may use to prevent or
            restrict access to the Platform (or parts thereof); (iv) decipher,
            decompile, disassemble, reverse engineer or otherwise attempt to
            derive any source code or underlying ideas or algorithms of any part
            of the Platform, except to the limited extent applicable laws
            specifically prohibit such restriction; (v) modify, translate, or
            otherwise create derivative works of any part of the Platform; or
            (vi) copy, rent, lease, distribute, or otherwise transfer any or all
            of the rights that you receive hereunder.
          </div>
        </div>
        <div className="flex flex-row">
          <div className="mr-4">g)</div>
          <div>
            You will not access the Platform, and/or its services, or the
            personal information of other Users, available on the Platform in
            order to build a similar or competitive website, product, or
            service.
          </div>
        </div>
        <div className="flex flex-row">
          <div className="mr-4">h)</div>
          <div>
            If you are an Individual, you will not access the Platform and/or
            its services for the purpose of hiring, recruiting or inviting any
            other User to attend any program, event, function, seminar,
            conference, talk, etc.
          </div>
        </div>
        <div className="flex flex-row">
          <div className="mr-4">i)</div>
          <div>
            You agree to immediately notify us of any unauthorised use, or
            suspected unauthorized use of your Account, or any other breach of
            security, in relation to your personal information on the Platform.
          </div>
        </div>
        <div className="flex flex-row">
          <div className="mr-4">j)</div>
          <div>
            One Account shall not be used by more than one User. Any use of an
            Account by a third-party would deem to be unauthorised usage. The
            Company reserves the right to disable and remove any such Account.
          </div>
        </div>
        <div className="flex flex-row">
          <div className="mr-4">k)</div>
          <div>
            You agree that the Company may introduce (whether by written, oral, data, or other form of communication)
            you to one or more of its clients and other business associates (“<FontBold text="Associate(s)" />”). The identity of the
            Associates, and all other information concerning the Associates (including without limitation, all mailing
            information, phone and fax numbers, email addresses and other contact information) introduced hereunder
            are the property of the Associates / Company, and shall be treated as confidential and proprietary
            information of the Associates / Company, its affiliates, officers, directors, shareholders, employees, agents,
            representatives, successors and assigns. You shall not use such information, except in the context of any
            arrangement with the Company in which the Company is directly and actively involved, and never without
            Company’s prior written approval. You further agree that neither you nor your employees, affiliates or assigns,
            shall enter into, or otherwise arrange (either for yourself or any other person or entity) any business
            relationship or arrangement with the Associate, either directly or indirectly, or any of its affiliates, or
            accept any compensation or advantage with respect to such business relationship with the Associate except
            as directly through the Company.
          </div>
        </div>
      </div>
    ),
    className: "mt-5",
  },
  {
    title: "USE OF YOUR INFORMATION",
    description: (
      <div className="flex flex-col">
        <div className="flex flex-row">
          <div className="mr-4">a)</div>
          <div>
            You may provide only information that you own or have the right to
            use. We may only use the information you provide as permitted by our
            Privacy Policy and applicable law. Please closely review our Privacy
            Policy for more information regarding how we use and disclose your
            personal information. Our Privacy Policy is hereby incorporated into
            these Terms by this reference.
          </div>
        </div>
        <div className="flex flex-row">
          <div className="mr-4">b)</div>
          <div>
            We ensure easy access to the Users by providing an option to update
            your Account information. We reserve the right to moderate the
            changes or updates requested by you.
          </div>
        </div>
        <div className="flex flex-row">
          <div className="mr-4">c)</div>
          <div>
            We reserve the right to maintain, delete or destroy all information
            and materials posted or uploaded through the Services, pursuant to
            our internal record retention and/or destruction policies. We
            (may/may not) make use of third-party cloud services providers or
            use our own service infrastructure for hosting the servers and
            databases. While we make commercially reasonable efforts to ensure
            that the data stored on our servers is persistent and always
            available to the User, we will not be responsible in the event of
            failure of the third-party servers or any other factors outside our
            reasonable control that may cause the User’s data to be permanently
            deleted, irretrievable, or temporarily inaccessible.
          </div>
        </div>
        <div className="flex flex-row">
          <div className="mr-4">d)</div>
          <div>
            You acknowledge and agree that we may preserve your information and
            may also disclose your related information if required to do so by
            law; or in the good faith belief that such preservation or
            disclosure is reasonably necessary to: (a) comply with legal
            process, applicable laws or government requests; (b) enforce these
            Terms and Conditions; (c) respond to claims that any of your usage
            of the Platform violates the rights of third parties; (d) detect,
            prevent, or otherwise address fraud, security or technical issues;
            or (e) protect the rights, property, or personal safety of the
            Platform, its users, or the public.
          </div>
        </div>
      </div>
    ),
    className: "mt-5",
  },
  {
    title: "THIRD PARTY SITES",
    description: (
      <div className="flex flex-col">
        <div className="flex flex-row">
          <div className="mr-4">a)</div>
          <div>
            While availing Services, Users may connect with third-party service
            providers. The Company is not responsible for, and does not endorse,
            any third-party services mentioned on the Platform. It is hereby
            stated that Company shall in no way be responsible for any acts or
            omissions of third parties. Any transaction, dealings or
            communication otherwise that the User may have with such third
            parties are at the User’s own risk and we make no warranties,
            express or implied regarding the quality or suitability of the
            services or products of such third-party vendors. You may be
            redirected to a third-party website upon clicking on such links,
            these websites will be governed by its privacy policy and terms of
            use. We shall not be responsible for any transaction or
            dissemination of information that may take place while accessing
            these third-party websites.
          </div>
        </div>
        <div className="flex flex-row">
          <div className="mr-4">b)</div>
          <div>
            We attempt to be as accurate as possible. However, we do not warrant
            descriptions of our content or other content of this site is
            accurate, complete, reliable, current, or error-free. Also, your
            access to the Platform may also be occasionally suspended or
            restricted to allow for repairs, maintenance, or the introduction of
            new facilities or at any time without prior notice. We will attempt
            to limit the frequency and duration of any such suspension or
            restriction. We also provide links to other sites over which we have
            no control. We are not responsible for the availability of such
            external sites or resources and we do not endorse and are not
            responsible or liable for any content, advertising, products or
            other material on or available from such sites or resources.
          </div>
        </div>
        <div className="flex flex-row">
          <div className="mr-4">c)</div>
          <div>
            We cannot and will not assure that other Users are or will be
            complying with the foregoing rules or any other provisions of these
            Terms, and, as between you and us, you hereby assume all risk of
            harm or injury resulting from any such lack of compliance.
          </div>
        </div>
        <div className="flex flex-row">
          <div className="mr-4">d)</div>
          <div>
            You acknowledge that when you access a link that leaves the
            services, the site you will enter into is not controlled by us and
            different terms of use and privacy policy may apply. By assessing
            links to other sites, You acknowledge that we are not responsible
            for those sites. We reserve the right to disable links to and / or
            from third-party sites to the Services, although we are under no
            obligation to do so.
          </div>
        </div>
      </div>
    ),
    className: "mt-5",
  },
  {
    title: "INTELLECTUAL PROPERTY",
    description: (
      <div className="flex flex-col">
        <div className="flex flex-row">
          <div className="mr-4">a)</div>
          <div>
            Company shall own all right, title and interest (including patent rights, copyrights, trade secret rights, mask work rights,
            trademark rights and all other rights of any sort throughout the world) relating to any and all inventions (whether or not
            patentable), works of authorship, mask works, designations, designs, know-how, the Platform, the content of the
            Platform, video recordings, ideas and information, google text chats which are subject matter of services (collectively
            referred to as <FontBold text="“Intellectual Properties” / “Intellectual Property Rights”" />).
          </div>
        </div>
        <div className="flex flex-row">
          <div className="mr-4">b)</div>
          <div>
            Notwithstanding anything contained in these Terms and Conditions, Intellectual Properties include all rights of paternity,
            integrity, disclosure and withdrawal and any other rights that may be known as or referred to as “moral rights,” “artist’s
            rights,” “droit moral,” or the like.
          </div>
        </div>
        <div className="flex flex-row">
          <div className="mr-4">c)</div>
          <div>
            While rendering Services, Company directly or through its representatives, may provide Users with certain materials
            relevant to the Services, which may be in the form of audio, video, written and oral content <FontBold text="(“Company Materials”)." />
            Company Materials shall be the exclusive property of the Company. User hereby agrees and acknowledges that he/she
            shall ensure that the Company Materials are not shared with any third party, without Company’s prior written consent
            and any breach of such nature shall cause financial and irreparable injury to Company. Company hereby provides User
            with a revocable, non-exclusive, non-transferable, non-sublicensable, limited license to use the Company Materials solely
            for its personal purpose and not for any commercial use.
          </div>
        </div>
        <div className="flex flex-row">
          <div className="mr-4">d)</div>
          <div>
            While availing Services, Brand may directly or through its representatives, provide the Company and Influencer with
            certain materials relevant to the Campaign, which may be in the form of audio, video, written and oral content and the
            Campaign Subject Matter <FontBold text="(“Campaign Materials”)" />. The Intellectual Properties shared as Campaign Materials shall be
            the exclusive property of the Brand. The Company and the Influencer hereby agrees and acknowledges that they shall
            ensure that the Campaign Materials are not shared with any third party, without Brand’s written consent. The Brand
            hereby provides the Company and the Influencer with a revocable, non-exclusive, non-transferable, non-sublicensable,
            limited license to use the Campaign Materials solely for the purpose of rendering Services.
          </div>
        </div>
        <div className="flex flex-row">
          <div className="mr-4">e)</div>
          <div>
            As part of a Campaign, the Influencer may be creating material which may be categorised as an Intellectual Property
            <FontBold text="(“Campaign Deliverable”)" />, the ownership whereof shall remain with the Influencer. The Influencer grants an exclusive,
            transferrable and royalty-free right and license to the Brand to use the Campaign Deliverable that the Influencer creates
            in the course of providing the services for a Campaign for such duration as per the terms set out in the PO/Platform or
            such other communication between the Company, Brand and the Influencer. In such cases if the Brand wishes to purchase
            the Campaign Deliverables or extend the usage term beyond that which was initially agreed, the Influencer, Brand and
            the Company will mutually discuss and negotiate the terms of such ancillary transaction and record the same in writing
            over an email, PO or any other means determined by the Company.
          </div>
        </div>
        <div className="flex flex-row">
          <div className="mr-4">f)</div>
          <div>
            In the event that the Influencer: (a) accepts a Campaign that requires the Brand to own all the Campaign Deliverables
            created, or (b) the Company to own all the Campaign Deliverables created in case of Campaign Deliverables that are
            created for Campaigns under One Pop (the Brand or the Company, for the purpose of this course will be referred to as
            “Deliverable Owner”, as the case may be), the Influencer agrees and understand that they waive any future rights to
            revoke such ownership. In such cases, if the Influencer delivers or is required to deliver to Deliverable Owner any work
            product in connection with the Campaign, including but not limited to concepts, works, inventions, information,
            drawings, designs, videos, pictures, or software (whether completed or in-progress) (collectively, "Work Product"), then
            Deliverable Owner owns, or upon assignment by the Influencer will own, all right, title and interest (including, but not
            limited to, all trademarks, trade secrets, copyrights, patents and any other intellectual property or proprietary rights)
            (collectively "Proprietary Rights") in such Work Product, except that Work Product does not include: any work made by
            Influencer prior to the engagement for the Campaign ("Pre-Existing Work"). If the Work Product has been specially
            ordered and commissioned by Deliverable Owner, Influencer agrees that the Work Product is a "work made for hire" for
            copyright purposes, with all copyrights in the Work Product owned by Deliverable Owner. To the extent that the Work
            Product does not qualify as a work made for hire under applicable law, and to the extent that the Work Product includes
            material subject to copyright, patent, trade secret, or any Proprietary Rights protection, Influencer hereby assigns to
            Deliverable Owner (or to such of its affiliates as it may designate), its successors and assigns in perpetuity, all right, title
            and interest in and to the Work Product, including, but not limited to, all rights in and to any inventions, designs and
            Proprietary Rights embodied in the Work Product or developed in the course of Influencer’s creation of the Work Product,
            on a worldwide basis. The foregoing assignment includes a license under any current and future patents owned or
            licensable by Influencer to the extent necessary to combine the Work Product or any derivative works or modifications
            thereof with any product, service, offering, software or intellectual property of Deliverable Owner. Influencer will
            execute any documents and undertake such other acts in connection with such assignment that Deliverable Owner may
            reasonably request to perfect, register or enforce Deliverable Owner’s ownership of the rights so conveyed in and to the
            Work Products. Influencer will enter into agreements with its personnel or any other party as necessary to establish
            Deliverable Owner’s sole ownership in Work Product, and upon Deliverable Owner’s request, Influencer will provide
            Deliverable Owner with copies of such agreements. At any time upon request from Deliverable Owner and upon
            termination or expiration of these Terms and Conditions, Influencer will deliver to Deliverable Owner in tangible form
            all materials containing Work Product, whether complete or in process. To the extent Pre-Existing Work of Influencer is
            embodied in any Work Product, deliverables or Proprietary Rights, Influencer hereby grants Deliverable Owner a non-
            exclusive, worldwide, perpetual, irrevocable, fully paid up license to (a) use, make, have made, sell, offer to sell,
            reproduce, perform, display, distribute, and import such Pre-Existing Work, (b) adapt, modify, and create derivative
            works of such Pre-Existing Work, and (c) sublicense the foregoing rights. The Brand and Influencer will remain liable
            and responsible for the intellectual property rights in the materials and content shared by them respectively.
          </div>
        </div>
        <div className="flex flex-row">
          <div className="mr-4">g)</div>
          <div>
            The Brand and Influencer both agree and accept that the Company may use the Campaign Deliverables for the purposes
            of marketing, advertising and pitching the Services to third-parties and among the general public, and hereby grant to the
            Company the perpetual, irrevocable license to use (including the license to sublicense the usage) the Campaign
            Deliverables for this purpose.
          </div>
        </div>
      </div>
    ),
    className: "mt-5",
  },
  {
    title: "CONFIDENTIALITY",
    description: (
      <div className="flex flex-col">
        <div>
          The User agrees to keep all technical and non-technical information,
          which Company may have acquired before or after the date of these
          Terms and Conditions in relation to the technology, customers,
          business, operations, financial conditions, assets or affairs of the
          other Party resulting from negotiating these Terms and Conditions; or
          exercising its rights or performing its obligations under these Terms
          and Conditions; or which relates to the contents of these Terms and
          Conditions (or any agreement or arrangement entered into pursuant to
          these Terms and Conditions), including but not limited to business
          plans, business forecasts, research, technology and financial
          information, procurement requirements, purchasing requirements,
          manufacturing, customer lists, sales and merchandising efforts,
          marketing plans, experimental work, development, design details,
          specifications, engineering, copyrights, trade secrets, proprietary
          information, know-how, processes, equipment, algorithms, software
          programs, software source documents, and information in any way
          related to the current, future and proposed business, products and
          Services of Company confidential or any other information designated
          as confidential from time to time.
        </div>
        <div className="mt-2">
          Notwithstanding anything in the foregoing to the contrary,
          confidential information shall not include information which: (i) was
          known by the User prior to receiving the confidential information from
          Company; (b) becomes rightfully known to the User from a third-party
          source not known (after diligent inquiry) by the User to be under an
          obligation to Company to maintain confidentiality; (c) is or becomes
          publicly available through no fault of or failure to act by the User
          in breach of these Terms and Conditions; (d) is required to be
          disclosed in a judicial or administrative proceeding, or is otherwise
          requested or required to be disclosed by law or regulation.
        </div>
      </div>
    ),
    className: "mt-5",
  },
  {
    title: "RIGHTS AND OBLIGATIONS RELATING TO THE USAGE OF THE PLATFORM",
    description: (
      <div className="flex flex-col">
        <div>
          Users shall be prohibited from carrying out any illegal acts in the
          Platform including but not limited to acts mentioned below:
        </div>
        <div className="mt-2">
          <ul className="list-disc ml-3">
            <li>
              violating or attempting to violate the integrity or security of
              the Platform;
            </li>
            <li>
              transmitting any information on or through the Platform that is
              disruptive or competitive to the provision of our Services;
            </li>
            <li>
              intentionally submitting on the Platform any incomplete, false or
              inaccurate information;
            </li>
            <li>making any unsolicited communications to other Users;</li>
            <li>
              using any engine, software, tool, agent or other device or
              mechanism (such as spiders, robots, avatars or intelligent agents)
              to navigate or search the Platform;
            </li>
            <li>
              circumventing or disabling any digital rights management, usage
              rules, or other security features of the Platform;
            </li>
            <li>
              Any unlawful activities in the Platform which are prohibited by
              applicable laws.
            </li>
          </ul>
        </div>
        <div className="mt-2">
          The Company shall, upon obtaining knowledge by itself or been brought
          to actual knowledge by an affected person in writing or through email
          signed with electronic signature about any such information as
          mentioned above, be entitled to disable such information that is in
          contravention of this Clause. We shall also be entitled to preserve
          such information and associated records for such period as per
          applicable laws for production to governmental authorities for
          investigation purposes. In case of non-compliance with any applicable
          laws, rules or regulations, or the Terms and Conditions (including the
          Privacy Policy) by a User, we shall have the right to immediately
          terminate your access or usage rights to the Platform and Services and
          to remove non-compliant information from the Platform.
        </div>
        <div className="mt-2">
          We may disclose or transfer User-generated information to our
          affiliates or governmental authorities in such manner as permitted or
          required by applicable law, and you hereby consent to such transfer.
          In accordance with the applicable laws, we shall transfer Information,
          to any other body corporate or a person in India, or located in any
          other country, that ensures the same level of data protection that is
          adhered to by us, only if such transfer is necessary for the
          performance of the lawful contract between Company or any person on
          its behalf and the User or where the User has consented to data
          transfer.
        </div>
      </div>
    ),
    className: "mt-5",
  },
  {
    title: "SUSPENSION AND TERMINATION",
    description: (
      <div className="flex flex-col">
        <div className="flex flex-row">
          <div className="mr-4">a)</div>
          <div>
            These Terms and Conditions are effective unless and until terminated
            by either you or Company. You may terminate these Terms and
            Conditions at any time by notifying us that you no longer wish to
            use our Services, or when you cease using our Platform.
          </div>
        </div>
        <div className="flex flex-row">
          <div className="mr-4">b)</div>
          <div>
            We have the right to temporarily suspend access to the whole or any
            part of the Services for any reason whatsoever (including but not
            limited to technical/operational reasons) and shall be under no
            liability to you in such an event. Further, we may, but are not
            obliged to, give you notice of any interruption of access to the
            Service.
          </div>
        </div>
        <div className="flex flex-row">
          <div className="mr-4">c)</div>
          <div>
            We may temporarily suspend access to the whole or any part of the
            Services for pre-scheduled maintenance. If you choose to access the
            Platform or avail Services during such pre-scheduled maintenance, we
            cannot guarantee the availability of the Services and/or
            functionality of the Platform.
          </div>
        </div>
        <div className="flex flex-row">
          <div className="mr-4">d)</div>
          <div>
            We may terminate your usage of the Platform at any time for any
            reason, including breach of the Terms and Conditions. We have the
            right (but not the obligation) to refuse to grant access to
            Platform. Except for the rights and license granted in these terms,
            we reserve all other rights and grant no other rights or licenses,
            implied or otherwise.
          </div>
        </div>
        <div className="flex flex-row">
          <div className="mr-4">e)</div>
          <div>
            Once temporarily suspended, indefinitely suspended or terminated,
            the User may not continue to use the Platform under the same
            Account, a different Account or re-register under a new Account. On
            termination of an Account due to the reasons mentioned herein, such
            User shall no longer have access to data, messages and other
            material kept on the Platform by such User. All provisions of the
            Terms and Conditions, which by their nature should survive
            termination, shall survive termination, including, without
            limitation, warranty disclaimers, indemnity and limitations of
            liability.
          </div>
        </div>
        <div className="flex flex-row">
          <div className="mr-4">f)</div>
          <div>
            In the event of any termination of these Terms and Conditions, the
            User shall promptly and forthwith make payments accrued or due to
            Company.
          </div>
        </div>
        <div className="flex flex-row">
          <div className="mr-4">g)</div>
          <div>
            The Company may terminate the Influencer’s Account and may at their
            discretion block the Influencer from creating future Accounts on the
            Platform in the event the Influencer commits any acts in
            contravention to the Influencer Terms and Conditions or violates any
            Campaign instructions from the Brand or from the Company including
            without limitation uploading any Campaign Deliverables without the
            express consent/instructions from the Brand and/or committing any
            acts/omissions or passing any statements that are defamatory in
            nature or detrimental to the interests of the Company or Brand.
          </div>
        </div>
        <div className="flex flex-row">
          <div className="mr-4">h)</div>
          <div>
            Upon termination of these Terms and Conditions, any rights or
            obligations of the User existing at the time of expiration or
            termination, which, by their nature, survive the expiration or
            termination of these Terms and Conditions and such other provision
            as specifically identified in these Terms and Conditions, shall
            survive.
          </div>
        </div>
      </div>
    ),
    className: "mt-5",
  },
  {
    title: "ALERTS",
    description: (
      <div className="flex flex-col">
        <div className="flex flex-row">
          <div className="mr-4">a)</div>
          <div>
            The Company provides you with multiple automatic and/or customised
            alerts while providing Services.
          </div>
        </div>
        <div className="flex flex-row">
          <div className="mr-4">b)</div>
          <div>
            You understand and agree that any alerts provided to you through the
            Platform may be delayed or prevented by a variety of factors. We
            will do our best to provide alerts in a timely manner with accurate
            information. However, we neither guarantee the delivery nor the
            accuracy of the content of any alert. You also agree that we shall
            not be liable for any delays, failure to deliver, or misdirected
            delivery of any alert; for any errors in the content of an alert; or
            for any actions taken or not taken by you or any third party in
            reliance on an alert.
          </div>
        </div>
      </div>
    ),
  },
  {
    title: "CONTACT YOU",
    description: (
      <div className="flex flex-col">
        <div className="flex flex-row">
          <div className="mr-4">a)</div>
          <div>
            You agree that we may contact you through telephone, email, SMS, or
            any other means of communication for the purpose of:
            <ul className="list-disc ml-3">
              <li>Rendering Services</li>
              <li>
                Obtaining feedback in relation to Platform or our Services;
              </li>
              <li>
                Obtaining feedback in relation to any other Users listed on the
                Platform;
              </li>
              <li>
                Any events or initiatives that you may be interested in as part
                of the community of users
              </li>
              <li>
                Resolving any complaints, information, or queries by other Users
                regarding your critical content.
              </li>
            </ul>
          </div>
        </div>
        <div className="flex flex-row">
          <div className="mr-4">b)</div>
          <div>
            You agree to provide your fullest co-operation further to such
            communication by Company.
          </div>
        </div>
      </div>
    ),
  },
  {
    title: "DISCLAIMERS",
    description: (
      <div className="flex flex-col">
        <div className="flex flex-row">
          <div className="mr-4">a)</div>
          <div>
            THE SERVICE RENDERED ON COMPANY'S PLATFORM ARE PROVIDED "AS IS" AND
            “AS AVAILABLE”. COMPANY MAKES NO WARRANTIES, EXPRESS OR IMPLIED, AND
            HEREBY DISCLAIMS AND NEGATES ALL OTHER WARRANTIES, INCLUDING WITHOUT
            LIMITATION, IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY,
            FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT OF
            INTELLECTUAL PROPERTY OR OTHER VIOLATION OF RIGHTS. FURTHERMORE,
            COMPANY DOES NOT WARRANT OR MAKE ANY REPRESENTATIONS CONCERNING THE
            ACCURACY, LIKELY RESULTS, OR RELIABILITY OF THE USE OF THE MATERIALS
            ON ITS PLATFORM OR OTHERWISE RELATING TO SUCH MATERIALS OR ON ANY
            SITES LINKED TO THIS SITE.
          </div>
        </div>
        <div className="flex flex-row mt-2">
          <div className="mr-4">b)</div>
          <div>
            THE COMPANY, ITS DIRECTORS, EMPLOYEES, AGENTS, SUPPLIERS, SPONSORS
            AND PARTNERS DO NOT WARRANT THAT: (A) THE SERVICE WILL BE SECURE OR
            AVAILABLE AT ANY PARTICULAR TIME OR LOCATION; (B) ANY DEFECTS OR
            ERRORS WILL BE CORRECTED; (C) ANY CONTENT OR SOFTWARE AVAILABLE AT
            OR THROUGH THE SERVICE IS FREE OF VIRUSES OR OTHER HARMFUL
            COMPONENTS; (D) THE RESULTS OF USING THE SERVICE WILL MEET YOUR
            REQUIREMENTS; (E) ANY INFORMATION PROVIDED BY US IN ANY WAY WILL
            MEET YOUR REQUIREMENTS ; OR (F) THE PLATFORM WILL BE FREE OF
            GLITCHES. ON YOUR USE OF THE SERVICE IS SOLELY AT YOUR OWN RISK.
          </div>
        </div>
        <div className="flex flex-row mt-2">
          <div className="mr-4">c)</div>
          <div>
            COMPANY DOES NOT WARRANT THAT THE USER WILL BE ABLE TO USE THE
            PLATFORM AT ALL TIMES OR LOCATIONS ON THE OR THAT THE PLATFORM AND
            THE SERVICES PROVIDED THROUGH THE PLATFORM WILL BE UNINTERRUPTED OR
            ERROR-FREE.
          </div>
        </div>
        <div className="flex flex-row mt-2">
          <div className="mr-4">d)</div>
          <div>
            THE MATERIALS AS APPEARING ON THE PLATFORM MAY CONTAIN TYPOGRAPHICAL
            ERRORS, INACCURACIES OR OMISSIONS. COMPANY RESERVES THE RIGHT,
            ALTHOUGH IT IS UNDER NO OBLIGATION TO CORRECT ANY ERRORS,
            INACCURACIES OR OMISSIONS, AND TO CHANGE OR UPDATE INFORMATION OR
            MATERIAL AS APPEARING ON THE PLATFORM AT ANY TIME WITHOUT PRIOR
            NOTICE.
          </div>
        </div>
        <div className="flex flex-row mt-2">
          <div className="mr-4">e)</div>
          <div>
            WE DON’T PROMISE TO STORE OR KEEP SHOWING ANY INFORMATION AND
            CONTENT THAT YOU’VE POSTED. THE COMPANY DOES NOT PROVIDE A STORAGE
            SERVICE. YOU AGREE THAT WE HAVE NO OBLIGATION TO STORE, MAINTAIN OR
            PROVIDE YOU A COPY OF ANY CONTENT OR INFORMATION THAT YOU OR OTHERS
            PROVIDE, EXCEPT TO THE EXTENT REQUIRED BY APPLICABLE LAW AND AS
            NOTED IN OUR PRIVACY POLICY.
          </div>
        </div>
        <div className="flex flex-row mt-2">
          <div className="mr-4">f)</div>
          <div>
            WE DON’T MAKE ANY REPRESENTATIONS OR WARRANTIES REGARDING THE
            CAPABILITIES OR QUALIFICATIONS OF THE INFLUENCERS REGISTERED ON THE
            PLATFORM.
          </div>
        </div>
        <div className="flex flex-row mt-2">
          <div className="mr-4">g)</div>
          <div>
            THE COMPANY DISCLAIMS ALL WARRANTIES WITH REGARD TO THE CONTENTS OF
            THE CAMPAIGN.
          </div>
        </div>
        <div className="flex flex-row mt-2">
          <div className="mr-4">h)</div>
          <div>
            THE COMPANY DOES NOT EXERCISE ANY CONTROL ON THE INFLUENCER. NOTHING
            UNDER THESE TERMS AND CONDITIONS OF SERVICE SHALL CREATE A
            PARTNERSHIP OR RELATIONSHIP OF PRINCIPAL AND AGENT OR EMPLOYER AND
            EMPLOYEE BETWEEN THE COMPANY AND THE INFLUENCER. THE COMPANY IS ONLY
            A FACILITATOR OF THE SERVICES BEING PROVIDED BY THE INFLUENCER TO
            THE BRAND ON A NON-EXCLUSIVE BASIS AND THE INFLUENCER SHALL PROVIDE
            SUCH SERVICES TO THE BRAND ON A PRINCIPAL TO PRINCIPAL BASIS. BY
            AGREEING TO AVAIL THE SERVICES OF THE INFLUENCER THROUGH THE
            PLATFORM, THE BRAND HEREBY AGREES AND ACKNOWLEDGES THAT THE COMPANY
            SHALL NOT BE LIABLE IN ANY MANNER WHATSOEVER FOR ANY SERVICES
            PROVIDED BY THE INFLUENCER TO THE BRAND.
          </div>
        </div>
        <div className="flex flex-row mt-2">
          <div className="mr-4">i)</div>
          <div>
            BRAND SHALL BE RESPONSIBLE FOR THE CAMPAIGN SUBJECT MATTER ENDORSED
            FOR THE PURPOSE OF PROMOTIONS IN THE CAMPAIGN AND SHALL ENSURE THAT
            THE CAMPAIGN SUBJECT MATTER SHALL BE COMPLIANT WITH THE APPLICABLE
            LAWS, APPLICABLE STANDARDS OR ANY OTHER REQUIREMENT AS APPLICABLE
            FOR SUCH CATEGORY OF PRODUCT.
          </div>
        </div>
        <div className="flex flex-row mt-2">
          <div className="mr-4">j)</div>
          <div>
            NEITHER COMPANY NOR THE INFLUENCER HAS ANY WAY OF ASCERTAINING AND
            VERIFYING THE INGREDIENTS, QUALITY AND LEGAL COMPLIANCE OF THE
            CAMPAIGN SUBJECT MATTER AND ARE COMPLETELY RELYING ON THE
            REPRESENTATIONS MADE BY THE BRAND IN THIS REGARD.
          </div>
        </div>
      </div>
    ),
    className: "mt-5",
  },
  {
    title: "INDEMNITY",
    description: (
      <div className="flex flex-col">
        <div>
          You shall defend, indemnify, and hold harmless the Company, its
          affiliates/subsidiaries/joint venture partners and each of its, and
          its affiliates’/subsidiaries/joint venture partners’ employees,
          contractors, directors, suppliers and representatives from all
          liabilities, losses, claims, and expenses, including reasonable
          attorneys’ fees, that arise from or relate to (i) your use or misuse
          of, or access to, the Services and Platform; or (ii) your violation of
          the Terms; or any applicable law, contract, policy, regulation or
          other obligation. We reserve the right to assume the exclusive defense
          and control of any matter otherwise subject to indemnification by you,
          in which event you will assist and cooperate with us in connection
          therewith.
        </div>
      </div>
    ),
    className: "mt-5",
  },
  {
    title: "LIMITATION OF LIABILITY",
    description: (
      <div className="flex flex-col">
        <div>
          TO THE FULLEST EXTENT PERMITTED UNDER LAW, IN NO EVENT SHALL COMPANY
          BE LIABLE WHETHER IN TORT (INCLUDING FOR NEGLIGENCE OR BREACH OF
          STATUTORY DUTY), CONTRACT, MISREPRESENTATION, RESTITUTION OR OTHERWISE
          FOR (I) ANY SPECIAL, INCIDENTAL, INDIRECT OR CONSEQUENTIAL OR OTHER
          SUCH LOSS OR DAMAGE, INCLUDING BUT NOT LIMITED TO THOSE SUCH AS AND/OR
          RESULTING FROM LOSS OF PROFITS, LOSS OF BUSINESS, BUSINESS
          INTERRUPTION, COMPUTER FAILURE, LOSS OF BUSINESS INFORMATION,
          DEPLETION OF GOODWILL, LOSS OR CORRUPTION OF DATA OR INFORMATION, PURE
          ECONOMIC LOSS AND/OR SIMILAR LOSSES OR DAMAGES OF ANY KIND, HOWSOEVER
          ARISING IN CONNECTION WITH THESE TERMS, (II) FOR YOUR RELIANCE ON THE
          SERVICES (III) FOR ANY DIRECT DAMAGES THAT EXCEED Rs. 10,000/- (IV)
          FOR ANY MATTER BEYOND ITS OR THEIR REASONABLE CONTROL, EVEN IF USER
          HAS BEEN INFORMED IN ADVANCE OF THE POSSIBILITY OF SUCH DAMAGES. THE
          LIMITATIONS OF THIS SECTION WILL NOT APPLY TO ANY LIABILITY THAT
          CANNOT BE EXCLUDED OR LIMITED BY LAW.
        </div>
        <div className="mt-2">
          Our licensors and service providers will have no liability of any kind
          under these Terms and Conditions. Unless such restriction is
          prohibited by applicable law, you may not bring any claim under these
          Terms and Conditions more than twelve (12) months after the cause of
          action arises.
        </div>
      </div>
    ),
    className: "mt-5",
  },
  {
    title: "EXEMPTIONS TO LIMITATION OF LIABILITY",
    description: (
      <div className="flex flex-col">
        <div>
          You further agree and confirm that Company shall not be responsible,
          in any manner whatsoever, for any delay/unavailability of Services or
          failure to meet its obligations under the Terms and Conditions, which
          may be caused, directly or indirectly, due to:
          <div className="flex flex-col mt-3">
            <div className="flex flex-row">
              <div className="w-10">I.</div>
              <div>your failure to cooperate;</div>
            </div>
            <div className="flex flex-row">
              <div className="w-10">II.</div>
              <div>your unavailability and/or unresponsiveness;</div>
            </div>
            <div className="flex flex-row">
              <div className="w-10">III.</div>
              <div>
                your failure to provide accurate and complete information;
              </div>
            </div>
            <div className="flex flex-row">
              <div className="w-10">IV.</div>
              <div>
                your failure to provide or facilitate the submission of
                information in timely manner;
              </div>
            </div>
            <div className="flex flex-row">
              <div className="w-10">V.</div>
              <div>any event beyond Company’s reasonable control.</div>
            </div>
          </div>
        </div>
      </div>
    ),
    className: "mt-5",
  },
  {
    title: "UPDATES",
    description: `We reserve the right, at our sole discretion, to change, modify, add
    or remove portions of these Terms of Services, at any time without any
    prior written notice to you. We suggest that you regularly check these
    Terms of Services to apprise yourself of any updates. Your continued
    use of the Platform following the posting of changes will mean that
    you accept and agree to the revisions. As long as you comply with
    these Terms of Services, we grant you a personal, non-exclusive,
    non-transferable, limited privilege to enter and use the Platform.`,
    className: "mt-5",
  },
  {
    title: "SEVERABILITY AND WAIVER",
    description: `If any of these terms should be determined to be illegal, invalid or
    otherwise unenforceable by reason of the laws of any state in which
    these terms are intended to be effective, then to the extent and within
    the jurisdiction in which that term is illegal, invalid or unenforceable,
    it shall be severed and deleted and the remaining Terms and Conditions shall survive, remain in full force and effect and continue to be
    binding and enforceable. The failure of either party to exercise in
    any respect any right provided for herein shall not be deemed a waiver
    of any further rights hereunder.`,
    className: "mt-5",
  },
  {
    title: "FORCE MAJEURE",
    description: (
      <div className="flex flex-col">
        <div>
          If the performance of a Party’s obligations hereunder is prevented,
          restricted or interfered with by reason of fire, or by epidemic or
          pandemic, or other casualty or accident; strike or labour disputes;
          war or other violence; or any act or condition beyond the reasonable
          control of the Parties, or an act of God (each a “Force Majeure
          Event”), then the Parties shall be excused from such performance to
          the extent of such prevention, restriction or interference; provided,
          however, that the Parties shall give prompt notice within a period of
          three (3) days from the date of occurrence of the Force Majeure Event
          and providing a description to the other Party of such Force Majeure
          Event in such notice, including a description, in reasonable
          specificity, of the cause of the Force Majeure Event and the likely
          duration of the impact or delay caused by the Force Majeure Event; and
          provided further that the Parties shall use reasonable efforts to
          avoid or remove such cause of non-performance and shall continue
          performance hereunder whenever such causes are removed.
        </div>
        <div className="mt-2">
          If the Party’s performance of its obligations under these Terms and
          Conditions is suspended due to the occurrence of a Force Majeure Event
          for a period in excess of thirty (30) business days, the Parties may
          terminate these Terms and Conditions without incurring any charges.
        </div>
      </div>
    ),
    className: "mt-5",
  },
  {
    title: "RELATIONSHIP BETWEEN THE USER AND COMPANY",
    description: `Nothing in these Terms and Conditions shall be construed to create any relationship between Company and you other than that of a service provider and user. you do not have the authority to bind Company in any manner whatsoever.`,
    className: "mt-5",
  },
  {
    title: "NON-ASSIGNMENT",
    description: `These Terms are personal to you and you shall not assign or transfer or purport to assign or transfer the contract between you and us to any other person.`,
    className: "mt-5",
  },
  {
    title: "GOVERNING LAW, JURISDICTION AND DISPUTE RESOLUTION",
    description: `These Terms and Conditions are governed by the laws of India. Any action, suit, or other legal proceeding, which is commenced to resolve any matter arising under or relating to the Platform and/or the Services, shall be subject to the jurisdiction of the courts at Gurugram, Haryana, India.`,
    className: "mt-5",
  },
  {
    title: "ENTIRE AGREEMENT",
    description: `The Terms and Conditions are the entire agreement and understanding between you and Company with respect to the Services and usage of Platform.`,
    className: "mt-5",
  },
  {
    title: "GRIEVANCE REDRESSAL OFFICER",
    description: (
      <div className="flex flex-col">
        <div>
          In furtherance of the Information Technology Act, 2000 (“IT Act”) and
          the Information Technology (Intermediary Guidelines and Digital Media
          Ethics Code) Rules, 2021 (“Intermediary Guidelines”) a grievance
          officer is appointed to ensure compliance with the IT Act and the
          Intermediary guidelines.
        </div>
        <div className="mt-2">
          Any discrepancies or grievances with regard to content and/ or comment
          or breach of the Terms and Conditions shall be taken up with the
          designated grievance officer as mentioned below in writing or through
          email signed with the electronic signature to:
        </div>
        <div className="mt-2">
          Attention: Jivesh Gupta
          <br /> Email ID:
          <Space />
          <Link
            text="grievances@oneimpression.io"
            href="grievances@oneimpression.io"
            type={MAIL}
          />
          <br /> Address: Wework DLF two horizon centre, 5th floor, DLF phase 5
          sector 43, golf course road, Gurugram, Haryana
        </div>
        <div className="mt-2">
          The grievance officer shall revert to every complaint within 24 hours
          of receipt of the complaint. Further, the Company shall take best
          possible efforts to redress the complaint within 15 days of receipt of
          the complaint. Any suggestions by Company regarding use of the
          Services shall not be construed as a warranty.
        </div>
      </div>
    ),
    className: "mt-5",
  },
  {
    title: "SUPPORT",
    description: (
      <div className="flex flex-col">
        <div>
          The Company offers an email, calling and in-app-based support system.
          In case you require any assistance or support, you may access support
          resources or contact our support by calling at
          <Space />
          <Link text="+91 9311124148" href="+91 9311124148" type={PHONE} /> or
          use the “Help and Support” function on the Platform or email at
          <Space />
          <Link
            text="support@oneimpression.io"
            href="support@oneimpression.io"
            type={MAIL}
          />
          . The Company provides Support on Monday – Friday between the hours of
          10 a.m. – 6:30 p.m. IST (except public holidays).
        </div>
        <div className="mt-2">
          The User agrees and acknowledges that the Company shall address and
          attempt to resolve the complaint received in accordance with the
          standard policies and procedures adopted by the Company, the User’s
          disapproval/discontent with the outcome/mode of redressal shall not be
          deemed to mean non-redressal of the complaint by the Company. Any
          suggestions by Company regarding use of the Service shall not be
          construed as a warranty.
        </div>
        <div className="mt-2">
          In furtherance of the Consumer Protection Act 2019 (
          <FontBold text="“Consumer Protection Act”" />) and the Consumer
          Protection (E- Commerce) Rules 2020 (
          <FontBold text="“E-Commerce Rules”" />) a nodal officer is appointed
          to ensure compliance with the Consumer Protection Act and the
          E-Commerce Rules.
        </div>
        <div className="mt-2">
          The details of the grievance officer to whom consumer grievances can
          be addressed are as follows:
        </div>
        <div className="mt-2">
          Name: Jivesh Gupta
          <br /> Contact Details:
          <Space />
          <Link href="+91 9311124148" text="+91 9311124148" type={PHONE} />
          <br /> Designation of such officer: Director
          <br />
        </div>
        <div className="mt-2">
          The Company shall revert to every complaint within 48 hours of receipt
          of the complaint. Further, the Company shall take best possible
          efforts to redress the complaint within 30 days of receipt of the
          complaint. Any suggestions by Company regarding use of the Services
          shall not be construed as a warranty.
        </div>
        <div className="mt-2">
          The User agrees and acknowledges that the Company shall address and
          attempt to resolve the complaint received in accordance with the
          standard policies and procedures adopted by the Company, the User’s
          disapproval/discontent with the outcome/mode of redressal shall not be
          deemed to mean non-redressal of the complaint by the Company. Any
          suggestions by Company regarding use of the Service shall not be
          construed as a warranty.
        </div>
      </div>
    ),
    className: "mt-5",
  },
  {
    title: "CONTACT",
    description: (
      <div>
        If you have any questions regarding the Services or usage of the
        Platform, please contact Company at
        <Space />
        <Link
          text="support@oneimpression.io"
          href="support@oneimpression.io"
          type={MAIL}
        />
        . Please note that for the purpose of validation, you shall be required
        to provide information (including, but not limited to contact number or
        registered mobile number, etc.) for the purpose of validation and taking
        your service request.
      </div>
    ),
    className: "mt-5",
  },
];

export const TermsOfService = () => {
  return (
    <TermsAndConditionsLayout>
      <div className="mx-auto mt-8 lg:mt-16 px-5 max-w-lg sm:max-w-screen-sm md:max-w-screen-md lg:max-w-screen-xl">
        <div className="text-2xl not-italic text-center underline font-bold font-serif text-oi-gray-900 tracking-wider mb-5">
          TERMS OF SERVICE
        </div>
        <div className="mb-5">
          <div className="text-xs not-italic font-normal font-serif text-black mb-2">
            <div>
              THESE TERMS OF SERVICE IS AN ELECTRONIC RECORD IN THE FORM OF AN
              ELECTRONIC CONTRACT FORMED UNDER APPLICABLE LAWS AND THE AMENDED
              PROVISIONS PERTAINING TO ELECTRONIC DOCUMENTS / RECORDS IN VARIOUS
              STATUTES. THESE TERMS OF SERVICE DO NOT REQUIRE ANY PHYSICAL,
              ELECTRONIC OR DIGITAL SIGNATURE.
            </div>
            <div className="mt-6 mb-6">
              PLEASE READ THESE TERMS OF SERVICE CAREFULLY. BY USING THE
              WEBSITE, YOU INDICATE THAT YOU UNDERSTAND, AGREE AND CONSENT TO
              THESE TERMS OF SERVICE. IF YOU DO NOT AGREE WITH THE TERMS OF
              THESE TERMS OF SERVICE, PLEASE DO NOT USE THE PLATFORM OR
              SERVICES.
            </div>
            <div>
              These Terms of Service (“
              <FontBold text="Terms" />
              ”) of the website located at the URL
              <Space />
              <Link text={OI_DOMAIN_URL} href={OI_DOMAIN_URL} />
              , and the other associated/ancillary applications, products,
              websites, subdomains and services and all other variations of the
              same (collectively referred to as “<FontBold text="Platform" />
              ”) is between ANYCAST TECHNOLOGY PRIVATE LIMITED (referred as “
              <FontBold text="Company" />” or “<FontBold text="we" />” or “
              <FontBold text="us" />” or “<FontBold text="our" />
              ”), a company incorporated under the Companies Act, 2013 with its
              registered office situated at WeWork DLF Two Horizon Centre, 5th
              Floor, DLF Phase 5 Sector-43, Golf Course Road, Gurugram Haryana
              122002, India, and the registered Users, defined to include any
              person who registers himself/herself/itself on the Platform and
              holds an Account on the Platform or a visitor on the Platform, as
              the case may be (referred as "<FontBold text="you" />" or "
              <FontBold text="your" />" or “<FontBold text="User" />
              ”). These Terms and Conditions describe the terms on which the
              Company offers its services ("
              <FontBold text="Service(s)" />
              ").
            </div>
            <div className="mt-6">
              These Terms are a contract between you and Company. These Terms
              shall be read together with the
              <Space />
              <Link text="Privacy Policy" href="/privacy-policy" /> or other
              terms and condition with all other notices, disclaimers,
              guidelines appearing on the Platform from time to time
              (collectively referred to as "
              <FontBold text="Terms and Conditions" />
              ") constitute the entire agreement upon which you are allowed to
              access and use the Platform and avail the Services. By accessing
              this Platform, you are agreeing to be bound by these Terms and
              Conditions, all applicable laws and regulations, and agree that
              you are responsible for compliance with any applicable local laws.
              Your continued use of the Platform shall constitute your
              acceptance to the Terms and Conditions, as revised from time to
              time.
            </div>
            <div className="mt-6">
              You and Company shall hereinafter be individually referred to as a
              <span className="font-bold"> “Party”</span> and collectively as
              the <span className="font-bold">“Parties”</span>
            </div>
            <div className="mt-10">
              {BulletArray.map(({ title, description, className }, index) => (
                <NormalBulletText
                  number={index + 1}
                  title={title}
                  className={className}
                  description={description}
                  key={`${index}_${title}_bullet`}
                />
              ))}
            </div>
            <div className="mt-16">
              <div className="text-2xl text-center">
                ADDITIONAL TERMS FOR BRANDS AND INFLUENCERS PARTICIPATING FOR
                THE BRAND ONE POP PROGRAM
              </div>
              <div className="flex flex-col ml-10 w-4/5 mt-4">
                {additionalTermsForBrandsAndInfluencer.map(
                  ({ serial, value }, index) => (
                    <div
                      className="flex flex-row mt-4"
                      key={`${index}_${serial}_addTerms`}
                    >
                      <div className="mr-4">{serial}.</div>
                      <div>{value}</div>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </TermsAndConditionsLayout>
  );
};
